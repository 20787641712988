@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link {
    @apply text-blue-500 hover:text-blue-600 hover:underline;
  }
  .item {
    @apply border-b border-gray-300 text-center last:border-b-0;
  }
}
